import React, { useState, useRef } from "react";
import "../../styling/templates.css";
import HCaptcha from "@hcaptcha/react-hcaptcha";

const styles = {
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    textAlign: "left",
  },
  td: {
    textAlign: "left",
  },
};

function App() {
  const [result, setResult] = useState("");
  const [isError, setIsError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState("");
  const hCaptchaRef = useRef(null);

  const handleCaptchaVerify = (token) => {
    setCaptchaToken(token);
    setCaptchaError("");
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult(" ");
    setIsError(false);

    if (!captchaToken) {
      setCaptchaError("Please complete the hCaptcha verification.");
      setIsError(true);
      return;
    }

    const formData = new FormData(event.target);

    formData.append("subject", "BPPL Careers Center Form");
    formData.append("access_key", "3b5055db-a06b-4b8d-9ce0-2e62dd32aa39");
    formData.append("h-captcha-response", captchaToken);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    if (res.success) {
      setResult(res.message);
      setIsError(false);

      event.target.reset();
      setCaptchaToken(null); // Reset captcha token
      hCaptchaRef.current.resetCaptcha(); // Clear the hCaptcha tick
      setCaptchaError("");
    } else {
      setResult(res.message);
      setIsError(true);
    }
  };

  return (
    <div className="about-us-form">
      <h2 className="about-us-form-topic">Careers Centre</h2>
      <br />
      <h2 className="about-us-form-topic-2">
        <div style={{ marginTop: "-10px" }}>Be a part of BPPL group</div>
      </h2>
      <br />
      <p className="about-us-form-para">
        At our companies, more than 900 people are employed. As demand for our
        products and services increases, so does our need for talented
        individuals to join us.
      </p>
      <br />
      <form
        onSubmit={onSubmit}
        encType="multipart/form-data"
        id="bpplholdingscareerscentre"
      >
        <label className="about-us-form-label">Available Position:</label>
        <br />
        <select name="position" required className="about-us-form-select">
          <option value="GM">GM</option>
          <option value="VP">VP</option>
          <option value="AGM">AGM</option>
        </select>
        <br />
        <br />
        <label className="about-us-form-label">Upload CV:</label>
        <br />
        <div className="about-us-form-cv">
          <input type="file" required name="attach" />
        </div>
        <br />
        {/* Add the hCaptcha component */}
        <label className="about-us-form-label">
          Please verify that you are human:
        </label>
        <HCaptcha
          sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
          reCaptchaCompat={false}
          onVerify={handleCaptchaVerify}
          ref={hCaptchaRef} // Set the ref to access hCaptcha
        />
        <br />
        {/* <input
          type="hidden"
          name="ccemail"
          value="shenali@3cs.solutions; priyankara@3cs.solutions"
        /> */}
        <input
          type="hidden"
          name="ccemail"
          value="info@bpplholdings.com; admin@3cs.lk"
        />
        <input type="checkbox" name="botcheck" style={{ display: "none" }} />
        <input name="from_name" type="hidden" value="BPPL Careers" />
        <input name="subject" type="hidden" value="BPPL Careers Center Form" />
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>
                <input className="submitButton" type="submit" value="Submit" />
              </th>
              <th style={styles.th}>
                <div style={{ marginLeft: "20px" }}>
                  <span className={isError ? "error-text" : "success-text"}>
                    {captchaError || result}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <br />
      </form>
    </div>
  );
}

export default App;
